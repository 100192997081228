<template>
  <div class="faq-wrapper">
    <div class="faq">
      <p class="faq-title">Часто задаваемые вопросы</p>
      <div class="faq-continer">
        <div class="question" v-for="(item, index) in questions" :key="index">
          <div
            class="question-title"
            :class="{ active: item.active }"
            @click="toggleActive(index)"
          >
            <p>{{ item.title }}</p>
            <div class="icon" :class="{ active: item.active }">
              <svg
                width="19"
                height="11"
                viewBox="0 0 19 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.5 1.5L9.5 9.5L17.5 1.5"
                  stroke="#1C1C1C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="question-content" :class="{ active: item.active }">
            <p v-for="(p, idx) in item.content" :key="idx + p">{{ p }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FAQ',
  data() {
    return {
      questions: [
        {
          title:
            'Если я не хочу заниматься трейдингом крипты какие у меня есть варианты получения прибыли с нее?',
          content: [
            'Во первых можно ее просто купить и хранить, ждать пока она вырастет в цене, инвестиции в долгосрок.',
            'Во вторых можно отдать свои средства в управление профессиональному  трейдеру под процент для торговли.',
            'В третьих можно подключить робота или торговый алгоритм который будет приносить тебе прибыль без твоего участия.'
          ],
          active: false
        },
        {
          title: 'Что нужно для того чтоб научиться грамотно трейдить?',
          content: [
            'Нужно как минимум погрузиться в тему, выделить для этого дела время, просмотреть очень много материала, возможно купить качественные курсы по трейдингу и конечно же практика на небольших суммах, научиться не поддаваться эмоциям, только холодных расчет и придерживаться торгового плана, учитывать риски.'
          ],
          active: false
        },
        {
          title:
            'Сколько мне надо времени чтоб начать успешно зарабатывать на крипте?',
          content: [
            'Тут самое главное начать и первые результаты можно уже получить за первую неделю.'
          ],
          active: false
        },
        {
          title: 'Есть ли еще обучающие материалы за деньги?',
          content: [
            'Нет, я не продаю никакие обучающие материалы, я их даю бесплатно членам моей команды.'
          ],
          active: false
        },
        {
          title: 'Что мне нужно для того чтоб попасть к тебе в команду?',
          content: [
            'Самое главное это желание зарабатывать на криптовалюте и активная позиция по жизни.'
          ],
          active: false
        }
      ]
    }
  },

  methods: {
    toggleActive(index) {
      this.questions.forEach((item, i) => {
        if (i === index) {
          item.active = !item.active
        } else {
          item.active = false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.faq-wrapper {
  background: url('../assets/faqBG.png') center/ cover no-repeat;
}
.faq {
  max-width: 900px;
  margin: auto;
  @media (max-width: 900px) {
    padding: 0 16px 0 16px;
  }
}
.faq-continer {
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.faq-title {
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  text-align: center;
  font-size: 64px;
  font-weight: 700;
  line-height: 94.85px;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 71.14px;
    text-align: center;
  }
}

.question-title {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 20px 32px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  background: #1c1c1c;
  font-size: 24px;
  line-height: 28.8px;

  cursor: pointer;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.2px;
  }

  p {
    text-align: left;
  }
  &.active {
    background: #ffa113;
    color: #1c1c1c;
  }

  .icon {
    transition: transform 0.5s ease-in-out;
    transform: rotate(0deg);

    &.active {
      transform: rotate(180deg);
    }
  }
}

.icon {
  background: #ffa113;
  padding: 3px;
  border-radius: 50%;
  min-width: 34px;
  min-height: 34px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease;
  background: #3737374d;
  border-radius: 24px;
  padding: 30px 30px 0px;
  margin-top: -30px;
  font-size: 18px;
  line-height: 22.2px;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  p {
    text-align: left;
  }
  &.active {
    max-height: 500px;
  }
}
</style>
