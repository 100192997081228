<template>
  <div class="container">
    <div class="mrk"></div>
    <div class="form-wrapper" id="order">
      <p class="form-title">Успейте записаться</p>
      <p class="form-desc">
        Бесплатный вебинар, на котором ты научишься правильно инвестировать в
        крипту. Не упусти свой шанс!
      </p>
      <!-- <div class="salebot_tilda_block"></div> -->
      <!-- <input class="form-input" type="email" placeholder="Электронная почта" />
      <input class="form-input" type="tel" placeholder="Номер телефона" /> -->
      <button class="btn" @click="showModal">Записаться на вебинар</button>
      <div class="ticket">
        <span class="promo-time">{{ nextDay }} в 19:00 мск </span>
        <span class="promo-text">Начало вебинара</span>
      </div>
      <div class="blur"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderComponent',
  data() {
    return {
      scriptLoaded: false
    }
  },
  computed: {
    nextDay() {
      const today = new Date()
      today.setDate(today.getDate() + 1)
      const day = String(today.getDate()).padStart(2, '0')
      const month = String(today.getMonth() + 1).padStart(2, '0')
      return `${day}.${month}`
    }
  },
  mounted() {
    // this.initForm()
  },

  methods: {
    showModal() {
      this.$emit('showModal')
    },
    loadScript(src) {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve()
          return
        }
        const script = document.createElement('script')
        script.src = src
        script.onload = () => resolve()
        script.onerror = () => reject(new Error(`Failed to load script ${src}`))
        document.head.appendChild(script)
      })
    },
    waitForSalebotTildaIntegration() {
      // this.initForm()

      const checkInterval = setInterval(() => {
        if (window.SalebotTildaIntegration) {
          clearInterval(checkInterval)
          this.initForm()
        }
      }, 100)
    },
    initForm() {
      window.SalebotTildaIntegration.init({
        project_id: 348034,
        guid: 'on_webinar'
      })
    }

    // initForm() {
    //   console.log('initForm')
    //   if (window.SalebotTildaIntegration) {
    //     window.SalebotTildaIntegration.init({
    //       project_id: 348034,
    //       guid: 'on_webinar'
    //     })
    //   } else {
    //     console.error('SalebotTildaIntegration is not available.')
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  height: 605px;
  margin: 120px 0 120px 0;
  @media (max-width: 1024px) {
    height: auto;
    margin: 60px 0 120px 0;
  }
}
.mrk {
  position: absolute;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background: url('../assets/orderMRK.svg') 0 0 / 80% 100% no-repeat;
  min-height: 605px;
  width: 52vw;
  @media (max-width: 1370px) {
    width: 45vw;
  }
  @media (max-width: 1024px) {
    position: static;
    width: 100vw;
    min-height: 400px;
    background: url('../assets/orderMRK.svg') 0 0 / 50% 100% no-repeat;
  }
  @media (max-width: 768px) {
    margin-bottom: auto;

    background: url('../assets/orderMRK.svg') 0 0 / 70% 100% no-repeat;
  }
  @media (max-width: 430px) {
    margin-bottom: -25px;
    background: url('../assets/orderMRK.svg') 0 0 / 100% 100% no-repeat;
  }
}
.form-wrapper {
  padding-top: 50px;
  position: absolute;
  left: 48%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 606px;
  @media (max-width: 1370px) {
    left: 43%;
  }
  @media (max-width: 1270px) {
    left: 35%;
  }
  @media (max-width: 1100px) {
    left: 30%;
  }
  @media (max-width: 1024px) {
    margin: auto;
    position: relative;
    left: auto;
    max-width: 90vw;
  }
}
.form-title {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 94.85px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 57.6px;
    max-width: 70%;
  }
}
.form-desc {
  margin: 0;
  margin-bottom: 16px;
  width: 87%;
  color: #ababab;
  font-family: 'Ubuntu', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
}
.btn {
  color: #fff;
  background: radial-gradient(50% 50% at 50% 50%, #ffa113 50%, #ffbf5f 100%);
  z-index: 7;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  border-radius: 16px;
  width: 100%;
  padding: 32px 0 32px 0;
  cursor: pointer;
  border: none;
}

.form-input {
  border: none;
  background: #232323;
  border-radius: 16px;
  padding: 24px 32px 24px 32px;
  color: #fff;
  outline-color: rgba(255, 161, 19, 0.7);
}

.ticket {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 25px;
  right: -100px;
  top: 15%;
  transform: rotate(-30deg);
  background: transparent;
  width: 200px;
  height: 150px;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 130px;
    height: 135px;
    top: 0px;
    right: 0px;
    transform: rotate(30deg);
    padding: 10px 30px;
  }
}

.ticket::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform: rotate(30deg);

  background-image: url('../assets/ticket.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.blur {
  position: absolute;
  top: 15%;
  content: '';
  right: -100px;

  z-index: -2;
  border-radius: 50%;
  background-color: rgba(255, 161, 19, 0.5);
  box-shadow: 0 0 20px rgba(255, 161, 19, 0.7), 0 0 40px rgba(255, 161, 19, 0.5),
    0 0 60px rgba(255, 161, 19, 0.3);
  filter: blur(50px);
  width: 250px;
  height: 200px;
  @media (max-width: 1024px) {
    top: -15px;
    right: -40px;
  }
}
.promo-time {
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.6px;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
  }
}
.promo-text {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 22.4px;
  color: #ababab;
  @media (max-width: 1024px) {
    font-size: 14px;
    font-weight: 300;
    line-height: 16.8px;
  }
}
</style>
