<template>
  <footer class="footer">
    <span class="astahov">ASTAHOV PRODUCTION</span>
    <span class="legal">2024 Все права защищены</span>
    <button class="order-btn" @click="showModal">Записаться на вебинар</button>
  </footer>
</template>
<script>
export default {
  name: 'FooterComponent',
  methods: {
    showModal() {
      this.$emit('showModal')
    },
    scrollToElement() {
      const element = document.getElementById('order')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 74px 60px 40px 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 60px;
    padding: 120px 54px 60px 54px;
  }
}

.astahov {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 42px;
  text-transform: uppercase;
}

.legal {
  font-family: 'Ubuntu', sans-serif;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
}

.order-btn {
  text-transform: uppercase;
  border: 1px solid #ffffff;
  background: #1c1c1c;
  cursor: pointer;
  color: #ffffff;
  border-radius: 16px;
  padding: 12px 24px;
}
</style>
