<template>
  <div v-show="isVisible" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <button class="close-button" @click="closeModal">X</button>

      <div class="salebot_tilda_block"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OrderModal',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },

  mounted() {
    this.initForm()
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    initForm() {
      window.SalebotTildaIntegration.init({
        project_id: 348034,
        guid: 'on_webinar'
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-overlay {
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.modal-content {
  z-index: 900;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 400px;
  max-width: 90%;
}

.close-button {
  z-index: 999;
  color: black;
  padding: 0 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}
</style>
