<template>
  <header class="header">
    <span class="astahov">ASTAHOV PRODUCTION</span>
    <button class="order-btn" @click="showModal">Записаться на вебинар</button>
  </header>
</template>
<script>
export default {
  name: 'HeaderComponent',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    scrollToElement() {
      const element = document.getElementById('order')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
    showModal() {
      this.$emit('showModal')
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 60px 47px 60px;

  @media (max-width: 768px) {
    padding: 16px 0px 40px 16px;
  }
}

.astahov {
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 42px;
  text-transform: uppercase;
}

.order-btn {
  text-transform: uppercase;
  border: 1px solid #ffffff;
  background: #1c1c1c;
  cursor: pointer;
  color: #ffffff;
  border-radius: 16px;
  padding: 12px 24px;

  @media (max-width: 768px) {
    display: none;
  }
}
</style>
