<template>
  <div class="chips-container">
    <p class="chips-title">фишки</p>
    <div class="groups-wrapper">
      <div class="group-wrapper">
        <div class="chip-container small-continer">
          <p class="chip-title">Крипта</p>
          <p class="chip-desc">Расскажу о преимуществах криптовалюты</p>
          <img class="chip-img-1" src="../assets/chip-img-1-1.png" alt="chip" />
        </div>
        <div class="chip-container container-2 large-container">
          <p class="chip-title">Раскрою 5 мифов</p>
          <div class="chip-desc-wrapper">
            <div class="chip-desc-elem">
              <p class="desc-elem-title">Миф 1</p>
              <p class="desc-elem-desc">
                Криптовалюта только для тех кто разбирается в технических
                деталях
              </p>
            </div>
            <div class="desc-elem-wrap">
              <div class="chip-desc-elem">
                <p class="desc-elem-title">Миф 2</p>
                <p class="desc-elem-desc">
                  Криптовалюта это просто цифровые знаки
                </p>
              </div>
              <div class="chip-desc-elem">
                <p class="desc-elem-title">Миф 3</p>
                <p class="desc-elem-desc">
                  Нет гарантий если что то пойдет не так
                </p>
              </div>
            </div>
            <div class="desc-elem-wrap">
              <div class="chip-desc-elem">
                <p class="desc-elem-title">Миф 4</p>
                <p class="desc-elem-desc">Крипта это хайповая тема</p>
              </div>
              <div class="chip-desc-elem">
                <p class="desc-elem-title">Миф 5</p>
                <p class="desc-elem-desc">Все отзывы фейковые</p>
              </div>
            </div>
          </div>
          <img class="chip-img-2" src="../assets/chip-img-2-1.png" alt="chip" />
        </div>
      </div>
      <div class="group-wrapper">
        <div class="chip-container chip-container-9 large-container">
          <img class="chip-img-3" src="../assets/chip-img-3-1.png" alt="chip" />
          <div class="chip-inner-9">
            <p class="chip-title">Cложности и успехи в трейдинге:</p>
            <p class="chip-desc">
              Что нужно для того чтоб стать успешным трейдером, сколько времени,
              средств и нервов нужно отдать чтоб торговать в плюс и какие плюсы
              можно делать в месяц и год.
            </p>
          </div>
        </div>
        <div class="chip-container small-continer">
          <p class="chip-title">Личный опыт</p>
          <p class="chip-desc">
            Расскажу про свои успехи и неудачи в инвестициях
          </p>
          <img class="chip-img-4" src="../assets/chip-img-4-1.png" alt="chip" />
        </div>
      </div>
      <div class="group-wrapper">
        <div class="chip-container small-continer">
          <p class="chip-title">Способ торговли</p>
          <p class="chip-desc">Плюсы и минусы алгоритмической торговли</p>
          <img class="chip-img-5" src="../assets/chip-img-5-1.png" alt="chip" />
        </div>
        <div class="chip-container small-continer">
          <p class="chip-title">Обучение</p>
          <p class="chip-desc">
            Научу зарабатывать на крипте на собственном примере
          </p>
          <img class="chip-img-6" src="../assets/chip-img-6-1.png" alt="chip" />
        </div>
        <div class="chip-container small-continer">
          <p class="chip-title">Сообщество</p>
          <p class="chip-desc">
            Расскажу про созданное мною сообщество единомышленников партнеров
          </p>
          <img class="chip-img-7" src="../assets/chip-img-7-1.png" alt="chip" />
        </div>
      </div>
      <div class="group-wrapper">
        <div class="chip-container small-continer">
          <p class="chip-title">Личный заработок</p>
          <p class="chip-desc">
            Расскажу про свою прибыль за определенный период времени
          </p>
          <img class="chip-img-8" src="../assets/chip-img-8-1.png" alt="chip" />
        </div>
        <div class="chip-container chip-container-9 large-container">
          <img class="chip-img-9" src="../assets/chip-img-9-1.png" alt="chip" />
          <div class="chip-inner-9">
            <p class="chip-title">Рефералка</p>
            <p class="chip-desc">
              Расскажу о рефералке крупнейшего крипто проекта со стабильной
              прибылью до 16% в месяц
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChipsComponent'
}
</script>
<style lang="scss" scoped>
.chips-container {
  position: relative;
  z-index: 7;
  margin: -50px auto;
  padding: 60px 0 60px 0;
  background: #232323;
  border-radius: 40px;
  border: 1px solid #333333;
}
.chips-title {
  text-align: center;
  margin: 0;
  font-family: 'Oswald', sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 94.85px;
  text-transform: uppercase;
}
.group-wrapper {
  margin: auto;
  gap: 20px;
  max-width: 82%;
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
}
.groups-wrapper {
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.chip-desc-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  margin-top: 31px;
  @media (max-width: 680px) {
    align-items: center;
    flex-direction: column;
  }
}
.chip-desc-elem {
  flex: 1;
}
.desc-elem-wrap {
  display: flex;
  gap: 25px;
  flex: 1;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }
}
.chip-desc-elem {
  @media (max-width: 768px) {
    flex-grow: 1;
  }
}
.chip-container {
  padding: 0 24px 32px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #373737;
  border-radius: 16px;
  border: 1px solid grey;
  height: 212px;
  @media (max-width: 1024px) {
    min-height: 180px;
  }
  @media (max-width: 768px) {
    overflow: hidden;
    justify-content: flex-end;
    padding: 24px;
    min-height: 190px;
  }
}
.chip-container-2 {
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}
.chip-title {
  font-family: 'Oswald', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38.4px;
  text-align: left;
  color: #fff;
  margin: 0;
  @media (max-width: 430px) {
    font-size: 24px;
    line-height: 28.8px;
  }
}
.desc-elem-title {
  margin: 0;
  color: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 20.68px;
  text-align: left;
}

.desc-elem-desc {
  margin: 0;
  margin-top: 4px;
  font-family: 'Ubuntu', sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.38px;
  text-align: left;
}
.chip-desc {
  margin: 0;
  margin-top: 8px;
  color: #fff;
  font-family: 'Ubuntu', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.68px;
  text-align: left;
  @media (max-width: 530px) {
    font-size: 15px;
    line-height: 17.24px;
  }
}
.chip-img-1 {
  position: absolute;
  height: 214px;
  width: 299px;
  right: 0;
  top: 0;
  @media (max-width: 1100px) {
    width: 194.35px;
    height: 139.1px;
  }
  @media (max-width: 1024px) {
    width: 272.09px;
    height: 194.74px;
  }
  @media (max-width: 768px) {
    width: 298.96px;
    height: 213.11px;
  }
}

.container-2 {
  overflow: visible;
}
.chip-img-2 {
  position: absolute;
  height: 95.36px;
  width: 130.29px;
  right: -25px;
  top: -25px;
  @media (max-width: 768px) {
    right: -30px;
    top: -10px;
  }
}
.container-3 {
  @media (max-width: 768px) {
    min-height: 382px;
  }
}
.chip-img-3 {
  margin-right: 30px;
  width: 167px;
  height: 191px;
  margin-bottom: -35px;

  @media (max-width: 768px) {
    right: -20px;
    top: auto;
    bottom: 0;
    width: 218px;
    height: 249px;
  }
  @media (max-width: 530px) {
    position: absolute;
    bottom: 0;
  }
}
.container-4 {
  @media (max-width: 768px) {
    max-height: 180px;
  }
}
.chip-img-4 {
  position: absolute;
  height: 123.55px;
  width: 117.13px;
  right: 0;
  top: -10px;
  @media (max-width: 1024px) {
    width: 163.98px;
    height: 173px;
  }

  @media (max-width: 530px) {
    right: 0px;
    top: 0;
    width: 117.1px;
    height: 123.55px;
  }
}
.chip-img-5 {
  position: absolute;
  width: 147.01px;
  height: 145.77px;
  right: 0;
  top: -20px;
  mix-blend-mode: lighten;
  @media (max-width: 1024px) {
    right: -10px;

    width: 205.81px;
    height: 204.07px;
  }
  @media (max-width: 530px) {
    width: 147px;
    height: 145.75px;
  }
}
.chip-img-6 {
  position: absolute;
  width: 102.16px;
  height: 104.8px;
  right: 0;
  top: 0;
  @media (max-width: 1024px) {
    width: 142.82px;
    height: 146.72px;
  }
  @media (max-width: 530px) {
    width: 102.16px;
    height: 104.79px;
  }
}
.chip-img-7 {
  position: absolute;
  width: 100.26px;
  height: 119.36px;
  right: 0;
  top: 0;
  @media (max-width: 1024px) {
    width: 140.36px;
    height: 167.1px;
  }
  @media (max-width: 530px) {
    width: 100.26px;
    height: 119.36px;
  }
}
.chip-img-8 {
  position: absolute;
  width: 201.44px;
  height: 156.19px;
  right: 0;
  top: 0;
  @media (max-width: 1100px) {
    width: 151.82px;
    height: 117.13px;
  }
  @media (max-width: 1024px) {
    width: 201.44px;
    height: 156.19px;
  }
  @media (max-width: 768px) {
    width: 202.43px;
    height: 156.17px;
  }
}
.chip-img-9 {
  width: 225px;
  height: 181px;

  margin-bottom: -35px;
  @media (max-width: 530px) {
    width: 337.5px;
    height: 271.5px;

    position: absolute;
    right: 0;
    bottom: 0;
  }
  @media (max-width: 380px) {
    width: 253.125px;
    height: 203.625px;
  }
}
.chip-container-9 {
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}
.chip-inner-9 {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.small-continer {
  flex: 1;
}
.large-container {
  flex: 2;
  @media (max-width: 530px) {
    flex-wrap: wrap;
    min-height: 382px;
    align-content: flex-start;
    justify-content: flex-start;
  }
}
</style>
