<template>
  <div class="theme">
    <p class="theme-title">Темы</p>
    <p class="theme-subtitle">Которые я раскрою на вебинаре</p>

    <div class="themes-container">
      <img src="../assets/tcoin.png" class="tcoin" alt="coin" />
      <div class="blur"></div>
      <div
        v-for="(elem, idx) in themes"
        :key="elem.subtitle + idx"
        :class="['theme-elem', `elem-${idx + 1}`]"
      >
        <span class="elem-number">
          {{ `0${idx + 1}` }}
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="number-icon"
          >
            <g clip-path="url(#clip0_9_120)">
              <rect width="16" height="16" rx="4" fill="#FFA113" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.364 3.40738C14.5515 3.59491 14.6568 3.84921 14.6568 4.11438C14.6568 4.37954 14.5515 4.63385 14.364 4.82138L6.86867 12.3167C6.76961 12.4158 6.65202 12.4944 6.52259 12.548C6.39316 12.6016 6.25443 12.6292 6.11433 12.6292C5.97424 12.6292 5.83551 12.6016 5.70608 12.548C5.57665 12.4944 5.45905 12.4158 5.36 12.3167L1.636 8.59338C1.54049 8.50113 1.46431 8.39079 1.4119 8.26878C1.35949 8.14678 1.3319 8.01556 1.33075 7.88278C1.3296 7.75 1.3549 7.61832 1.40518 7.49542C1.45546 7.37253 1.52971 7.26087 1.6236 7.16698C1.7175 7.07309 1.82915 6.99884 1.95205 6.94856C2.07494 6.89827 2.20662 6.87297 2.3394 6.87413C2.47218 6.87528 2.6034 6.90287 2.7254 6.95528C2.84741 7.00768 2.95775 7.08387 3.05 7.17938L6.114 10.2434L12.9493 3.40738C13.0422 3.31445 13.1525 3.24073 13.2738 3.19044C13.3952 3.14014 13.5253 3.11426 13.6567 3.11426C13.788 3.11426 13.9181 3.14014 14.0395 3.19044C14.1609 3.24073 14.2711 3.31445 14.364 3.40738Z"
                fill="#1C1C1C"
              />
            </g>
            <defs>
              <clipPath id="clip0_9_120">
                <rect width="16" height="16" rx="4" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
        <div class="elem-content">
          <p class="elem-title">{{ elem.title }}</p>
          <p class="elem-subtitle">{{ elem.subtitle }}</p>
        </div>
      </div>
      <img src="../assets/Rocket.png" class="trocket2" alt="rocket" />
      <div class="blur3"></div>
    </div>
    <img src="../assets/Rocket.png" class="trocket" alt="rocket" />
    <div class="blur2"></div>
  </div>
</template>
<script>
export default {
  name: 'ThemeComponent',
  data() {
    return {
      themes: [
        {
          title: 'Инвестиции',
          subtitle: 'Как научиться правильно инвестировать'
        },
        { title: 'Вклады', subtitle: 'Куда вкладывать свои деньги' },
        { title: 'Доход', subtitle: 'Как создать пассивный доход' },
        {
          title: 'Мифы',
          subtitle: 'Мифы, которые мешают зарабатывать много и регулярно'
        },
        {
          title: 'Результат',
          subtitle: 'Как повторить мой результат и выйти на доход 25000$ '
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.theme {
  position: relative;
}
.theme-title {
  font-family: 'Oswald', sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 94.85px;
  text-align: center;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 71.14px;
  }
}
.theme-subtitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: center;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
}
.themes-container {
  position: relative;
  padding-top: 0;
  // min-height: 80vh;
  @media (max-width: 1270px) {
    display: flex;

    min-height: 661px;
  }
  @media (max-width: 768px) {
    min-height: 100%;

    height: 490px;
    padding-top: 31;
    margin-bottom: -50px;
  }
}
.tcoin {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0%, -50%);
  z-index: 3;
  @media (max-width: 768px) {
    display: block;
  }
}
.blur {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: -50px;
  display: none;
  height: 125px;
  width: 125px;
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(255, 161, 19, 0.5);
  box-shadow: 0 0 20px rgba(255, 161, 19, 0.7), 0 0 40px rgba(255, 161, 19, 0.5),
    0 0 60px rgba(255, 161, 19, 0.3);
  filter: blur(30px);
  @media (max-width: 768px) {
    display: block;
  }
}
.theme-elem {
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 340px;
  position: absolute;
  z-index: 6;
  @media (max-width: 768px) {
    max-width: 299px;
    gap: 8px;
    max-width: 280px;
  }
}
.elem-1 {
  top: 76px;
  left: 9%;

  @media (max-width: 768px) {
    top: 31px;
    left: 17px;
  }
}
.elem-2 {
  top: 270px;
  left: 5%;
  @media (max-width: 768px) {
    top: 121px;
    left: 59px;
  }
}
.elem-3 {
  top: 460px;
  left: 4%;
  @media (max-width: 768px) {
    top: 204px;
    left: 89px;
  }
}
.elem-4 {
  top: 144px;
  left: auto;
  right: 11%;
  @media (max-width: 1270px) {
    top: 100px;
    right: 6%;
  }
  @media (max-width: 768px) {
    top: 287px;
    left: 59px;
  }
}
.elem-5 {
  top: 394px;
  left: auto;
  right: 4%;
  @media (max-width: 1270px) {
    top: 300px;
    left: auto;
    right: 2%;
  }
  @media (max-width: 768px) {
    top: 377px;
    left: 17px;
  }
}
.elem-number {
  position: relative;
  font-family: 'Oswald', sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 89.6px;

  @media (max-width: 768px) {
    font-size: 48px;
    font-weight: 700;
    line-height: 67.2px;
  }
}
.number-icon {
  position: absolute;
  left: -8px;
  top: 40px;
  @media (max-width: 768px) {
    top: 30px;
  }
}
.elem-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 50.4px;
  text-align: left;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
  }
}
.elem-subtitle {
  margin: 0;
  color: #ababab;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 19.6px;
    text-align: left;
  }
}
.trocket {
  display: none;
  @media (max-width: 768px) {
    position: relative;
    width: auto;
    z-index: 3;
    display: block;
    max-width: 90vw;
    height: auto;
    margin: auto;
  }
}
.blur2 {
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    transform: translate(0%, -50%);
    height: 125px;
    width: 125px;
    z-index: 2;
    background-color: rgba(255, 161, 19, 0.5);
    box-shadow: 0 0 20px rgba(255, 161, 19, 0.7),
      0 0 40px rgba(255, 161, 19, 0.5), 0 0 60px rgba(255, 161, 19, 0.3);
    filter: blur(120px);
    height: 500px;
    width: 100vw;
    bottom: -500px;
  }
}

.trocket2 {
  display: block;
  margin: auto;
  z-index: 3;
  position: relative;
  @media (max-width: 1270px) {
    width: 42%;
    margin: auto auto 0 auto;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.blur3 {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, 0%);
  z-index: 2;
  left: 50%;
  display: block;
  height: 525px;
  width: 60vw;
  max-width: 600px;
  background-color: rgba(255, 161, 19, 0.5);
  box-shadow: 0 0 20px rgba(255, 161, 19, 0.7), 0 0 40px rgba(255, 161, 19, 0.5),
    0 0 60px rgba(255, 161, 19, 0.3);
  filter: blur(120px);
  @media (max-width: 768px) {
    display: none;
  }
}
</style>
