<template>
  <div class="container">
    <div class="content">
      <h2>бесплатный доступ в мир Крипто инвестиций</h2>
      <h1>секретная стратегия крипто-инвестиций на 2024-2025 год</h1>
      <h3>
        Бесплатный вебинар, на котором я, Кирилл Астахов, расскажу свой путь от
        новичка в успешного инвестора
      </h3>

      <div class="constructor"></div>
      <div class="btn-continer">
        <button class="btn" @click="showModal">Записаться на вебинар</button>
        <div class="promo-wrapper">
          <span class="promo-time">{{ nextDay }} в 19:00 мск </span>
          <span class="promo-text">Начало вебинара</span>
        </div>
      </div>
    </div>
    <div class="backgrond2"></div>
    <div class="backgrond3"></div>
    <div class="backgrond4"></div>
    <img class="MRK" src="../assets/MRK.png" alt="mrK" />
    <div class="blur"></div>
    <!-- <div class="revenue">
        <div class="rev-img"></div>
        <div>
          <p>25 000 <span>$</span></p>
          <p>Мой стабильный доход</p>
        </div>
      </div> -->
    <!-- <div class="backgrond1"></div> -->
  </div>
</template>
<script>
export default {
  name: 'MainBanner',
  methods: {
    showModal() {
      this.$emit('showModal')
    },
    scrollToElement() {
      const element = document.getElementById('order')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  },
  computed: {
    nextDay() {
      const today = new Date()
      today.setDate(today.getDate() + 1)
      const day = String(today.getDate()).padStart(2, '0')
      const month = String(today.getMonth() + 1).padStart(2, '0')
      return `${day}.${month}`
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  overflow: hidden;
  position: relative;
  width: 100%;
  // min-height: 843px;
  padding-bottom: 150px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding-bottom: 0px;
  }
}
.container::after {
  content: '';
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #1c1c1c, transparent);
  z-index: 5;
  @media (max-width: 430px) {
    background-image: linear-gradient(to top, #1c1c1c, #000);

    filter: blur(100px);

    height: 300px;
  }
}

.content {
  // width: 46vw;
  // margin-left: 50px;
  // padding-top: 50px;
  padding: 0px 60px 0px 60px;
  max-width: 670px;
  position: relative;
  z-index: 6;
  @media (max-width: 768px) {
    max-width: 70%;
    padding: 0px 16px 0px 16px;
  }
  @media (max-width: 430px) {
    max-width: 85%;
  }
  @media (max-width: 380px) {
    max-width: 100%;
  }
  h2 {
    font-family: 'Ubuntu', sans-serif;
    color: #ffa113;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 12px;
      line-height: 18.8px;
    }
  }
  h1 {
    margin: 0;
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 94.85px;
    text-align: left;
    text-transform: uppercase;
    @media (max-width: 768px) {
      font-size: 36px;
      line-height: 53.35px;
    }
  }
  h3 {
    width: 90%;
    margin: 16px 0 0 0;
    color: #ababab;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    @media (max-width: 768px) {
      width: 65%;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
  .btn-continer {
    z-index: 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 380px) {
      align-items: center;
    }
  }
  .btn {
    color: #fff;
    background: radial-gradient(50% 50% at 50% 50%, #ffa113 50%, #ffbf5f 100%);
    z-index: 7;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    border-radius: 16px;
    border: none;
    width: 413px;
    padding: 32px 0 32px 0;
    cursor: pointer;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 22.4px;
      width: 328px;
    }
  }
  .promo-wrapper {
    margin-left: -80px;
    flex-grow: 1;
    z-index: 6;
    min-height: 92px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('../assets/RectangleG.png') 0 0 / contain no-repeat;
    justify-content: center;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-top: -15px;
      width: 328px;
    }
  }
  .promo-time {
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 33.6px;
    text-transform: uppercase;
    padding-left: 45px;

    @media (max-width: 768px) {
      padding-left: 0px;
    }
  }
  .promo-text {
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 22.4px;
    color: #ababab;
  }
}
.constructor {
  display: none;
  @media (max-width: 430px) {
    display: block;
    width: 100%;
    min-height: 300px;
  }
}
.backgrond2 {
  width: 171px;
  z-index: 2;
  height: 212px;
  position: absolute;
  right: 0;
  top: 50%;
  background-image: url('../assets/banner-bg-2.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  mix-blend-mode: color-dodge;
  @media (max-width: 768px) {
    right: 170px;
    top: 45%;
  }
}
.backgrond3 {
  z-index: 2;
  height: 82px;
  width: 218.67px;
  position: absolute;
  right: 530px;
  top: 13%;
  background-image: url('../assets/banner-bg-3.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  @media (max-width: 768px) {
    height: 48px;
    width: 128px;
    right: 220px;
    top: 39%;
  }
}
.backgrond4 {
  z-index: 8;
  height: 166.38px;
  width: 125px;
  position: absolute;
  left: 0;
  top: 29%;
  background-image: url('../assets/banner-bg-4.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 768px) {
    top: 13%;
    height: 65.42px;
    width: 80px;
  }
  @media (max-width: 530px) {
    top: 19%;
  }
  @media (max-width: 380px) {
    top: 22%;
  }
}

.MRK {
  position: absolute;
  background-color: transparent;
  z-index: 5;
  height: 100%;
  bottom: 0;
  right: 30px;
  @media (max-width: 1024px) {
    right: 0px;
  }

  @media (max-width: 768px) {
    bottom: 0px;
  }
  @media (max-width: 430px) {
    right: -71px;
    bottom: 75px;
    height: 570px;
  }
}

.blur {
  position: absolute;
  bottom: 0px;
  right: -10px;
  z-index: 3;
  width: 400px;
  height: 50%;
  border-radius: 50%;
  background-color: rgba(255, 161, 19, 0.5);
  box-shadow: 0 0 20px rgba(255, 161, 19, 0.7), 0 0 40px rgba(255, 161, 19, 0.5),
    0 0 60px rgba(255, 161, 19, 0.3);
  filter: blur(150px);
  @media (max-width: 768px) {
    width: 300px;
    height: 50%;
    filter: blur(60px);

    bottom: 50px;
    right: -100px;
  }
}
.backgrond1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-image: url('../assets/banner-bg-1.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>
