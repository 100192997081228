<template>
  <div id="app">
    <order-modal :isVisible="isModalVisible" @close="closeModal" />
    <div class="first-screen">
      <header-component @showModal="showModal" />
      <main-banner @showModal="showModal" />
    </div>
    <theme-component />
    <chips-component />
    <order-component @showModal="showModal" />
    <FAQ />
    <footer-component @showModal="showModal" />
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import MainBanner from './components/MainBanner.vue'
import ThemeComponent from './components/ThemeComponent.vue'
import ChipsComponent from './components/ChipsComponent.vue'
import OrderComponent from './components/OrderComponent.vue'
import FAQ from '../src/components/FAQ.vue'
import FooterComponent from './components/FooterComponent.vue'
import OrderModal from './components/OrderModal.vue'

export default {
  name: 'App',
  components: {
    OrderModal,
    HeaderComponent,
    MainBanner,
    ThemeComponent,
    ChipsComponent,
    OrderComponent,
    FAQ,
    FooterComponent
  },
  data() {
    return {
      isModalVisible: false
    }
  },

  methods: {
    showModal() {
      this.isModalVisible = true
    },

    closeModal() {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss">
html,
body {
  background: #1c1c1c;
  color: white;
  margin: 0;
  padding: 0;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow: visible;

  @media (max-width: 1440px) {
    overflow: hidden;
  }
}

.first-screen {
  background-image: url('@/assets/banner-bg-1.png');
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
</style>
